











































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import MapView from "@/components/core/Map.vue";
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Map } from "@/store/map";
import { Product } from "@/store/product";
import { App } from "@/store/app";
import { City } from "@/store/city";
import axios from "@/plugins/axios";
@Component({
  components: { MapView },
  computed: {},
})
export default class Saller extends Vue {
  file: any = [];
  farm: any = {};
  response: boolean = false;
  user: any = null;
  profile: any = null;
  saller: boolean = false;
  categories: any = null;
  chooseCategories: any = [];
  async created() {
    await Core.switchLoad(true);
    await this.loadFarm();
    await this.loadChoice();
    await this.setProductKey();
    this.product.product_type = 0;
    await Core.switchLoad(false);
    this.response = true;
  }

  async loadFarm() {
    this.user = await Auth.getUser();
    this.profile = await User.getProfileFull();
    this.farm = await Core.getHttp(`/api/user/farm/${this.user.pk}/`);
    this.response = true;
  }

  public product: any = {};
  public async setProductKey() {
    //this.product.id = 0
    this.product.category = this.chooseCategories;
    this.product.farm = this.farm.id;
    this.product.user = this.user.pk;
    this.product.status = this.product.status;
  }
  choices: any = {};
  public async loadChoice() {
    this.categories = await Core.getHttp(`/api/default/categories/`);
    this.choices = {
      product_type: await Product.ProductType,
      sell_type: await Product.SaleType,
      price_type: await Product.PriceType,
      status: await Product.StatusSell,
    };
  }
  public async loadProduct() {
    this.product = Core.getHttp(`/api/default/product/`);
  }

  public async storeProduct() {
    await this.setProductKey();
    let store = await Core.postHttp(`/api/default/product/`, this.product);
    if (store.id) {
      await this.storeImage(store.id);
      await App.success("บันทึกประกาศสำเร็จแล้ว");
      await this.$router.go(-1);
    }
  }

  public async updateProduct() {
    await this.setProductKey();
    let store = await Core.putHttp(
      `/api/default/product/${this.product.id}/`,
      this.product
    );
    if (store.id) {
      await App.success("บันทึกประกาศสำเร็จแล้ว");
    }
    await this.$router.go(-1);
  }

  public async removeProduct() {
    let store = await Core.deleteHttp(
      `/api/default/product/${this.product.id}/`
    );
    if (store.id) {
      await App.success("บันทึกประกาศสำเร็จแล้ว");
    }
    await this.$router.go(-1);
  }

  async filesSelected(event: any) {
    console.log(event);
  }

  async storeImage(id: number) {
    var formData = new FormData();
    for (let index = 0; index < this.file.length; index++) {
      formData.append(`file${index + 1}`, this.file[index].file);
    }
    await axios.put(`/api/default/productfile/${id}/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}
